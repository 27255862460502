<template>
  <div>
    <small v-show="input.allowClear">
      <a
        href="javascript:;"
        @click.prevent="clearSelection"
        v-html="$translation.t('Clear Selection')"
      ></a>
    </small>

    <v-radio-group
      v-model="model"
      :row="input.row !== false"
      hide-details="auto"
      class="th-mt-0"
    >
      <v-radio
        class="th-my-12 th-my-8-md body2"
        v-for="(label, value, index) in input.values"
        :key="index"
        :label="$translation.t(label)"
        :value="value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioInput",
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: { default: false },
  },
  data: () => ({
    model: null,
  }),
  mounted() {
    this.model = this.value ?? this.input.default;
  },
  methods: {
    clearSelection() {
      this.model = null;
    },
  },
  watch: {
    model(newInput) {
      this.$emit("input", newInput);
    },
  },
};
</script>

<style scoped></style>
